<template>
  <div class="form flex flex-col justify-center py-auto sm:px-6 lg:px-8">
    <div class=" sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white dark:bg-gray-900 py-8 px-4 sm:rounded-lg sm:px-10 shadow-sm">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="mt-3 mb-10 text-center text-2xl font-bold text-green-500">
            Sign in to your account
          </h2>
        </div>

        <form class="space-y-6" @submit.prevent="login">
          <div>
            <label for="email" class="block  font-medium text-gray-700 dark:text-white ">
              Email address
            </label>
            <div class="mt-1">
              <input
                v-model="email"
                id="email"
                name="email"
                type="email"
                autocomplete="off"
                required=""
                class="
                   appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                  "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block  font-medium text-gray-700 dark:text-white "
            >
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="password"
                id="password"
                name="password"
                type="password"
                autocomplete="off"
                required=""
                class="
                   appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300 dark:border-gray-700
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:border-green-500 dark:focus:border-green-500
                      sm:
                      bg-white dark:bg-gray-900
                      text-gray-900 dark:text-white
                  "
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                class="
                    h-4
                    w-4
                    text-green-600
                    focus:ring-green-500
                    border-gray-300
                    rounded
                  "
              />
              <label for="remember_me" class="ml-2 block  text-gray-900">
                Remember me
              </label>
            </div>

            <div class="">
              <router-link
                to="Register"
                href="#"
                class="font-medium text-green-600 hover:text-green-500"
              >
                Dont' have an account ?
              </router-link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  
                  font-medium
                  text-white
                  bg-green-600
                  hover:bg-green-700
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-green-500
                "
            >
              Sign in
            </button>
          </div>
        </form>
        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
             
            </div>
            <div class="relative flex justify-center ">
              <span class="px-2 text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div class="mt-6">
            <div>
              <button
                @click="loginWithGoogle"
                class="
                    w-full
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-gray-300 dark:border-gray-700
                    rounded-md
                    shadow-sm
                    bg-white
                    dark:bg-gray-900
                    font-medium
                    text-gray-500
                    hover:bg-gray-50
                  "
              >
                <span class="sr-only">Sign in with Google</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#f5bc00"
                    d="M43.6,20.1H42V20H24v8h11.3c-1.6,4.7-6.1,8-11.3,8c-6.6,0-12-5.4-12-12s5.4-12,12-12c3.1,0,5.8,1.2,8,3l5.7-5.7	C34,6.1,29.3,4,24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20C44,22.7,43.9,21.4,43.6,20.1z"
                  />
                  <path
                    fill="#6c19ff"
                    d="M43.6,20.1L43.6,20.1L42,20H24v8h11.3c-0.8,2.2-2.2,4.2-4.1,5.6c0,0,0,0,0,0l6.2,5.2C37,39.2,44,34,44,24	C44,22.7,43.9,21.4,43.6,20.1z"
                  />
                  <path
                    fill="#3ddab4"
                    d="M24,44c5.2,0,9.9-2,13.4-5.2l-6.2-5.2c-2,1.5-4.5,2.4-7.2,2.4c-5.2,0-9.6-3.3-11.3-7.9l-6.5,5	C9.5,39.6,16.2,44,24,44z"
                  />
                  <path
                    fill="#f55376"
                    d="M6.3,14.7l6.6,4.8C14.7,15.1,19,12,24,12c3.1,0,5.8,1.2,8,3l5.7-5.7C34,6.1,29.3,4,24,4	C16.3,4,9.7,8.3,6.3,14.7z"
                  />
                  <path
                    fill="#2100c4"
                    d="M26.6,35.7l6.8,6c1.5-0.8,2.9-1.8,4.1-2.9l-6.2-5.2C29.9,34.6,28.3,35.3,26.6,35.7z"
                  />
                  <path
                    fill="#eb0000"
                    d="M9.2,10.6c-1.1,1.2-2.1,2.6-2.9,4.1l3.9,2.9l2.6,1.9c0.6-1.6,1.6-3,2.8-4.1L9.2,10.6z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$store.dispatch("FIREABASE_LOGIN", {
        email: this.email,
        password: this.password,
        vm: this,
      });
    },
    loginWithGoogle() {
      this.$store.dispatch("FIREABASE_LOGIN_GOOGLE", {
        vm: this,
      });
    },
  },
};
</script>

<style>
.form {
  min-height: calc(100vh - 64px);
}
</style>
